<script>
import CreateModal from './CreateModal'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'EditModal',
  extends: CreateModal,
  props: {
    contentBlockId: {
      type: Number
    },
    contentBlockItem: {
      type: Object
    }
  },
  methods: {
    initFields () {
      if (this.item.type === 'article') {
        this.fetchArticlesByDocumentId()
      }
      this.dataLoaded = true
    },
    getContentBlockItem () {
      this.$store.dispatch('contentBlockItem/fetchOne', this.contentBlockItem.id)
        .then(() => {
          this.item = this.$store.getters['contentBlockItem/detail']
          this.initFields()
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('contentBlockItem/update', this.prepareRequest(this.item))
        .then(() => {
          if (this.$store.getters['contentBlockItem/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
            this.$emit('change')
            this.$emit('close')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['contentBlockItem/error'])
          }
        })
        .catch(error => console.log(error))
    },
    close () {
      this.$emit('close')
    }
  },
  created () {
    this.getContentBlockItem()
  }
}
</script>
