<template>
  <button
    class="btn-detail"
    type="button"
    :title="title"
  >
    <router-link
      :to="linkTo"
      :data-test="dataTest"
      tag="a"
      class="btn-detail__link"
    >
      <IconInfo class="btn-detail__icon" />
    </router-link>
  </button>
</template>

<script>
import IconInfo from '@/assets/img/svg/info.svg?inline'
export default {
  name: 'BtnDetail',
  props: {
    dataTest: {
      type: String,
      default: ''
    },
    linkTo: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    IconInfo
  }
}
</script>

<style scoped lang="scss">
  .btn-detail {
    background: #fff;
    width: rem(30px);
    height: rem(30px);
    border-radius: rem(6px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    &:hover {
      border: 1px solid #6599FE;
      .btn-detail__icon {
        fill: #6599FE;
      }
    }
    &__link {
      @include size(100%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__icon {
      width: rem(13px);
      height: rem(13px);
      fill: #9DAAC4;
    }
  }
</style>
