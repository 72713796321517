import Store from '../../store'

const getRubricTitle = (rubricId) => {
  return Store.getters['rubric/rubricById'](rubricId)?.title
}

const getRubricTitleWithSite = (rubricId) => {
  const rubric = Store.getters['rubric/rubricById'](rubricId)
  if (!rubric) {
    return `UNKNOWN-RUBRIC-${rubricId}`
  }
  const site = Store.getters['site/siteById'](rubric.site)
  let siteTitle = site.title
  if (site.shortTitle !== '') {
    siteTitle = site.shortTitle
  }

  return siteTitle + ' / ' + rubric.title
}

export default {
  getRubricTitle,
  getRubricTitleWithSite
}
