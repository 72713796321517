<template>
  <button
    class="btn-copy"
    type="button"
    :title="title"
    @click="$emit('click', $event)"
    :data-test="dataTest"
  >
    <span class="btn-copy__content">
      <IconCopy class="btn-copy__icon" />
    </span>
  </button>
</template>

<script>
import IconCopy from '@/assets/img/svg/copy.svg?inline'

export default {
  name: 'BtnCopy',
  props: {
    dataTest: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    IconCopy
  }
}
</script>

<style scoped lang="scss">
.btn-copy {
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  &__content {
    background: #fff;
    width: rem(30px);
    height: rem(30px);
    border-radius: rem(4px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border: 1px solid #6599FE;
      .btn-copy__icon {
        fill: #6599FE;
      }
    }
  }
  &__icon {
    width: rem(14px);
    height: auto;
    fill: #9DAAC4;
  }
}
</style>
