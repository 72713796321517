<template>
  <button
    class="hp-topic-offer-time-slot"
    type="button"
    @click="$emit('click', $event.target.value)"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'HpTopicOfferTimeSlot'
}
</script>

<style scoped lang="scss">
  .hp-topic-offer-time-slot {
    font-family: "Roboto", sans-serif;
    font-size: 0.8125rem;
    color: #8a96ac;
    height: 1.5rem;
    padding: 0 0.5rem;
    border-radius: 0.375rem;
    background: #fff;
    cursor: pointer;
    border: 1px solid #d1dbe4;
    &--active {
      background: #6599fe;
      color: #fff;
    }
  }
</style>
