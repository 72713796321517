<template>
    <span class="tooltip-custom">
        <slot></slot>
        <span
          v-if="title"
          class="tooltip-custom__info"
          :class="[position, customInfoClass]"
          :style="tooltipStyle"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </span>
    </span>
</template>

<script>
export default {
  name: 'TooltipSlot',
  props: {
    title: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'bottom'
    },
    customInfoClass: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '130px'
    }
  },
  data () {
    return {
      showToolTip: false
    }
  },
  computed: {
    tooltipStyle () {
      return {
        '--tooltip-width': this.width
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip-custom {
  position: relative;

  &:hover {
    .tooltip-custom__info {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &.absolute {
    position: absolute;
  }

  &__info {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    background: #000;
    color: #fff;
    z-index: 50;
    font-weight: 400;
    font-size: 12px;
    padding: 5px 10px;
    border-radius: 10px;
    left: -12px;
    width: var(--tooltip-width);

    &.top {
      bottom: calc(100% + 16px);
      &::before {
        bottom: -5px;
        border-top: 5px solid #000000;
      }
    }
    &.bottom {
      top: calc(100% + 11px);
      &::before {
        top: -5px;
        border-bottom: 5px solid #000000;
      }
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      position: absolute;
      left: 14px;
    }
  }
}
</style>
