<template>
  <app-modal
    modal-size="modal-md"
    :title="$t('hp.delete_modal_header')"
    @close="close"
    data-test="hp_close_modal"
  >
    <!-- Body -->
    <template slot="body">
      {{ $t('hp.delete_modal_text') }}
    </template>

    <!-- Footer -->
    <template slot="footer">
      <button
        type="button"
        class="btn btn-inverse router-link-active"
        @click="close"
        data-test="hp_item_delete_cancel"
      >
        {{ $t('hp.delete_modal_no') }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        @click="deleteRecord"
        data-test="hp_item_delete_confirm"
      >
        {{ $t('hp.delete_modal_yes') }}
      </button>
    </template>
  </app-modal>
</template>

<script>
import NotifyService from '../../services/NotifyService'
import Modal from '../shared/Modal'

export default {
  name: 'DeleteModal',
  props: {
    contentBlockItem: {
      type: Object
    }
  },
  components: {
    appModal: Modal
  },
  methods: {
    deleteRecord () {
      this.$store.dispatch('contentBlockItem/deleteRecord', this.contentBlockItem)
        .then(() => {
          if (this.$store.getters['contentBlockItem/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$emit('post-delete')
            this.$emit('close')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['contentBlockItem/error'])
          }
        })
        .catch(error => console.log(error))
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
