<template>
  <app-modal
    :title="$tc('hp.create_modal_header')"
    @close="close"
  >
    <!-- Header -->
    <template v-slot:header>
      <button
        v-if="(articleListSimple || articleListHpTopicOffer) === false"
        type="button"
        class="btn btn-success"
        @click="save"
        data-test="hp_item_create_save"
        :disabled="disabledSaveButton"
      >
        <i class="fa fa-save "></i> {{ $t('buttons.save') }}
      </button>
      <button
        v-if="articleListSimple || articleListHpTopicOffer"
        type="button"
        class="btn btn-default"
        @click="closeArticleLists"
        data-test="btn_back"
      >
        {{ $t('buttons.back') }}
      </button>
    </template>

    <!-- Body -->
    <template v-slot:body>
      <section
        v-if="(articleListSimple || articleListHpTopicOffer) === false"
        class="content form-horizontal"
      >
        <div class="row">
          <div class="col-lg-12">
            <input type="hidden" v-model="item.id">
            <div class="row">
              <div class="col-md-10">
                <div class="form-group">
                  <label>{{ $t('hp.type') }}</label><br>
                  <label
                    class="btn btn-secondary m-r-5"
                    :class="{ 'active': item.type === types.article }"
                    data-test="btn_internal"
                  >
                    <input
                      type="radio"
                      v-model="item.type"
                      value="article"
                      id="item_type_article"
                    >
                    {{ $t('hp.internal') }}
                  </label>
                  <label
                    class="btn btn-secondary m-r-5"
                    :class="{ 'active': item.type === types.external }"
                    data-test="btn_external"
                  >
                    <input
                      type="radio"
                      v-model="item.type"
                      value="external"
                      id="item_type_external"
                    >
                    {{ $t('hp.external') }}
                  </label>
                </div>
              </div>
              <div class="col-md-2 text-right">
                <app-select
                  v-model="item.position"
                  :options="positions"
                  id="item_position"
                  :label="contentBlock.title + ' position'"
                  no-empty-value
                >
                </app-select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <app-datepicker
                  v-model="item.publishedSince"
                  @blur="$v.compareDates.$touch()"
                  :error="$v.compareDates.$error"
                  id="item_publishedSince"
                  :label="$t('hp.published_since')"
                >
                </app-datepicker>
              </div>
              <div class="col-lg-3">
                <app-datepicker
                  v-model="item.publishedUntil"
                  @blur="$v.compareDates.$touch()"
                  :error="$v.compareDates.$error"
                  id="item_publishedUntil"
                  :label="$t('hp.published_until')"
                >
                </app-datepicker>
              </div>
            </div>
            <div class="row" v-if="item.type === types.article">
              <div class="col-md-3">
                <app-input
                  v-model="item.articleDocumentId"
                  @blur="$v.item.articleDocumentId.$touch()"
                  :error="$v.item.articleDocumentId.$error"
                  id="item_title"
                  @input="fetchArticlesByDocumentId"
                  :label="$t('hp.article_document_id')"
                  disable-form-group
                >
                </app-input>
              </div>
              <div class="col-md-3">
                <button
                  class="btn btn-info m-t-25"
                  @click="showArticleListSimple"
                  data-test="choose_article"
                >
                  <i class="fas fa-video"></i>
                  {{ $t('buttons.select_article') }}
                </button>
                <button
                  class="btn btn-info m-t-25"
                  @click="showArticleListHpTopicOffer"
                >
                  <i class="fas fa-video"></i>
                  {{ $t('hpTopicOffer.buttons.select_article') }}
                </button>
              </div>
              <div class="col-md-6">
                <app-input
                  v-model="item.alternativeTitle"
                  id="item_alternativeTitle"
                  :label="$t('hp.alternative_title')"
                  show-counter
                >
                </app-input>
              </div>
              <div class="col-md-12" v-if="article.id">
                <h4>{{ $t('hp.article_info') }}</h4>
                <app-info-article :article="article"></app-info-article>
              </div>
            </div>
            <div v-if="item.type === types.external" class="row">
              <div class="col-lg-8">
                <app-input
                  v-model="item.title"
                  @blur="$v.item.title.$touch()"
                  :error="$v.item.title.$error"
                  id="item_title"
                  :label="$t('hp.article.title')"
                  show-counter
                >
                </app-input>
                <app-input
                  v-if="item.alternativeTitle"
                  v-model="item.alternativeTitle"
                  id="item_alternativeTitle"
                  :label="$t('hp.alternative_title')"
                  show-counter
                >
                </app-input>
                <app-input
                  v-model="item.url"
                  @blur="$v.item.url.$touch()"
                  :error="$v.item.url.$error"
                  id="item_url"
                  :label="$t('hp.article.url')"
                >
                </app-input>
                <app-textarea
                  v-model="item.firstParagraph"
                  @blur="$v.item.firstParagraph.$touch()"
                  :error="$v.item.firstParagraph.$error"
                  id="item_firstParagraph"
                  :label="$t('hp.article.first_paragraph')"
                  show-counter
                >
                </app-textarea>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>{{ $t('hp.article.image') }}</label><br>
                      <app-media-select-button
                        :media-usage-type="mediaUsageType"
                        @set-media="setImage"
                        data-test="item_btn_select_image_from_dam"
                      >
                      </app-media-select-button>
                      <app-media-upload-button
                        :media-usage-type="mediaUsageType"
                        @set-media="setImage"
                        data-test="item_btn_select_image_from_pc"
                      >
                      </app-media-upload-button>
                      <app-media-url-button
                        :media-usage-type="mediaUsageType"
                        @set-media="setImage"
                        data-test="item_btn_select_image_from_url"
                      >
                      </app-media-url-button>
                      <input type="hidden" v-model="item.image">
                      <app-media-editable
                        v-if="item.imageInfo"
                        :media="item.imageInfo"
                        :width="360"
                        :height="201"
                        :media-usage-type="mediaUsageType"
                        @set-media="setImage"
                        @remove-media="removeImage"
                        class="m-t-10"
                      >
                      </app-media-editable>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <app-input
                  v-model="item.externalSiteName"
                  id="item_externalSiteName"
                  :label="$t('hp.article.site_name')"
                >
                </app-input>
                <app-input
                  v-model="item.siteUrl"
                  id="item_siteUrl"
                  :label="$t('hp.article.site_url')"
                >
                </app-input>
                <app-input
                  v-model="item.siteRubricName"
                  id="item_siteRubricName"
                  :label="$t('hp.article.rubric_name')"
                >
                </app-input>
                <app-input
                  v-model="item.siteRubricUrl"
                  id="item_siteRubricUrl"
                  :label="$t('hp.article.rubric_url')"
                >
                </app-input>
                <app-input
                  v-model="item.externalAuthor"
                  id="item_externalAuthor"
                  :label="$t('hp.article.author')"
                >
                </app-input>
                <label>{{ $t('hp.article.flags') }}</label>
                <app-flag-checkboxes :item="item"></app-flag-checkboxes>
              </div>
            </div>
          </div>
        </div>
      </section>
      <app-article-list-simple
        v-if="articleListSimple"
        show-select-button
        :selectable="false"
        :default-type="defaultArticleType"
        :default-status="defaultStatus"
        :hidden-on-hp="hiddenOnHp"
        @close="closeArticleLists"
        @select-record="setArticle"
      >
      </app-article-list-simple>
      <app-hp-topic-offer-list
        v-if="articleListHpTopicOffer"
        :selectable="true"
        @select-record="setHpTopicOfferArticle"
        @close="closeArticleLists"
      ></app-hp-topic-offer-list>
    </template>
  </app-modal>
</template>

<script>
import Moment from 'moment'
import Input from '../form/inputs/Input'
import Textarea from '../form/Textarea'
import Datepicker from '../form/Datepicker'
import Select from '../form/select/Select'
import { required, minLength, maxLength, url } from 'vuelidate/lib/validators'
import ContentBlockItemModel from '../../model/ContentBlockItem'
import ArticleModel from '../../model/ArticleModel'
import NotifyService from '../../services/NotifyService'
import CoreApi from '../../api/core'
import CbsApi from '../../api/contentBlockService'
import FlagCheckboxes from './FlagCheckboxes'
import { TYPE_ARTICLE, TYPE_EXTERNAL } from '@/model/ValueObject/ContentBlockItemTypes'
import InfoArticle from './InfoArticle'
import MediaSelectButton from '../shared/MediaSelectButton'
import MediaUploadButton from '../shared/MediaUploadButton'
import MediaUrlButton from '../shared/MediaUrlButton'
import MediaEditable from '../shared/MediaEditable'
import Modal from '../shared/Modal'
import ArticleListSimple from '../article/ArticleListSimple'
import HpTopicOfferList from '../../views/hp/HpTopicOfferList'
import { MEDIA_USAGE_TYPE_CONTENT_BLOCK_ITEM } from '@/model/ValueObject/MediaUsageTypes'
import { STATUS_READY_AND_PUBLISHED } from '@/services/article/ArticleFilterService'

export default {
  name: 'CreateModal',
  props: {
    contentBlockId: {
      type: Number
    },
    position: {
      type: Number,
      default: 1
    },
    publishedSince: {
      type: String
    },
    publishedUntil: {
      type: String
    }
  },
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      item: this._.cloneDeep(ContentBlockItemModel),
      article: ArticleModel,
      types: {
        article: TYPE_ARTICLE,
        external: TYPE_EXTERNAL
      },
      articleListSimple: false,
      articleListHpTopicOffer: false,
      disabledSaveButton: false,
      articleListModal: false,
      mediaUsageType: MEDIA_USAGE_TYPE_CONTENT_BLOCK_ITEM,
      defaultStatus: STATUS_READY_AND_PUBLISHED
    }
  },
  validations () {
    if (this.item.type === TYPE_EXTERNAL) {
      return {
        item: {
          title: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(95)
          },
          url: {
            required,
            url
          },
          firstParagraph: {
            required,
            maxLength: maxLength(255)
          },
          publishedSince: {
            required
          },
          publishedUntil: {
            required
          }
        },
        compareDates: {
          required
        }
      }
    }
    if (this.item.type === TYPE_ARTICLE) {
      return {
        item: {
          articleDocumentId: {
            required,
            minLength: minLength(36),
            maxLength: maxLength(36)
          },
          publishedSince: {
            required
          },
          publishedUntil: {
            required
          }
        },
        compareDates: {
          required
        }
      }
    }
  },
  computed: {
    compareDates () {
      if (Moment(this.item.publishedSince).isAfter(this.item.publishedUntil)) {
        return ''
      }
      return true
    },
    hiddenOnHp () {
      return this.$store.getters.vlm
    },
    contentBlock () {
      return this.$store.getters['contentBlock/getContentBlockById'](this.contentBlockId)
    },
    defaultArticleType () {
      return ''
    },
    positions () {
      var positions = []
      if (this.contentBlock) {
        for (let i = 1; i <= this.contentBlock.numberOfItems; i++) {
          positions.push(
            {
              id: i,
              title: i
            }
          )
        }
      }
      return positions
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appTextarea: Textarea,
    appSelect: Select,
    appDatepicker: Datepicker,
    appFlagCheckboxes: FlagCheckboxes,
    appInfoArticle: InfoArticle,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaUrlButton: MediaUrlButton,
    appMediaEditable: MediaEditable,
    appArticleListSimple: ArticleListSimple,
    appHpTopicOfferList: HpTopicOfferList
  },
  methods: {
    addOneSecondToPublishedSince (publishedSince) {
      const date = Moment(publishedSince)
      date.add(1, 's')
      return date.toISOString()
    },
    prepareRequest (item) {
      if (item.type === TYPE_ARTICLE && this.article.field.title) {
        item.title = this.article.field.title
      }
      if (item.type === TYPE_EXTERNAL) {
        delete item.articleDocumentId
      }
      if (item.publishedSince) {
        item.publishedSince = this.addOneSecondToPublishedSince(item.publishedSince)
      }
      if (item.type === TYPE_ARTICLE) {
        item.externalSiteName = this.article.expanded.site?.name
      }
      if (this.article.hpTopicOffer instanceof Array) {
        item.fbShared = this.article.setting.fbShared
      }
      return item
    },
    async save () {
      this.disabledSaveButton = true

      const gapCheckResult = await this.checkGap()

      if (!gapCheckResult) {
        // Check failed, do not save and display error message
        this.disabledSaveButton = false
        return
      }

      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        this.disabledSaveButton = false
        return
      }

      try {
        await this.$store.dispatch('contentBlockItem/create', this.prepareRequest(this.item))
        this.disabledSaveButton = false
        if (this.$store.getters['contentBlockItem/error'] === null) {
          NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
          this.$emit('change')
          this.$emit('close')
        } else {
          NotifyService.setErrorMessage(this.$store.getters['contentBlockItem/error'])
        }
      } catch (error) {
        console.log(error)
        NotifyService.setErrorMessage(this.$t('notify.error_saving_record'))
        this.disabledSaveButton = false
      }
    },
    fetchArticlesByDocumentId () {
      this.disabledSaveButton = true
      if (this.item.articleDocumentId.length !== 36) {
        return
      }
      this.article = ArticleModel
      const articleId = this.item.articleDocumentId
      const url = `/Article/hpTopicOffer/${articleId}`
      CoreApi().get(url)
        .then(response => {
          if (response.data) {
            this.article = response.data
          } else {
            NotifyService.setErrorMessage(this.$t('hp.article_not_found'))
          }
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.disabledSaveButton = false
        })
    },
    async checkGap ($event) {
      try {
        let url = 'ContentBlocks/' + this.item.contentBlock +
          '/checkGap?position=' + this.item.position +
          '&startDatetime=' + this.addOneSecondToPublishedSince(this.item.publishedSince) +
          '&endDatetime=' + this.item.publishedUntil

        if (this.item.id) {
          url = url + '&contentBlockItemId=' + this.item.id
        }

        const response = await CbsApi().get(url)

        if (response.status === 200 && response.data.isPositionEmpty) {
          return true // The check was successful
        } else {
          NotifyService.setErrorMessage(this.$t('hp.gap_busy'))
          return false // The check was not successful
        }
      } catch (error) {
        console.log(error)
        NotifyService.setErrorMessage(this.$t('hp.error_checking_gap'))
        return false // Communication error
      }
    },
    close () {
      this.$emit('close')
    },
    setImage (medias) {
      medias.forEach(media => {
        this.item.imageInfo = media
        this.item.image = media.id
      })
    },
    removeImage () {
      this.item.image = null
      this.item.imageInfo = null
    },
    showArticleListSimple () {
      this.articleListSimple = true
    },
    showArticleListHpTopicOffer () {
      const filter = this.$store.getters['hpTopicOffer/filter']
      filter.site = this.$store.getters['contentBlock/hpManagementFilter'].site
      this.$store.commit('hpTopicOffer/setFilter', filter)
      this.articleListHpTopicOffer = true
    },
    closeArticleLists () {
      this.articleListSimple = false
      this.articleListHpTopicOffer = false
    },
    setArticle (articles) {
      this.item.articleDocumentId = articles[0].documentId
      this.item.articleId = articles[0].id
      this.fetchArticlesByDocumentId()
      this.closeArticleLists()
    },
    setHpTopicOfferArticle (article) {
      this.item.articleDocumentId = article.documentId
      this.item.articleId = article.id
      this.fetchArticlesByDocumentId()
      this.closeArticleLists()
    }
  },
  created () {
    this.item.contentBlock = this.contentBlockId
    this.item.position = this.position
    this.item.publishedSince = this.publishedSince
    this.item.publishedUntil = this.publishedUntil
    this.dataLoaded = true
  }
}
</script>
