<template>
  <div class="timeline-ctr">
    <h6 class="timeline-ctr__text timeline-ctr__text--heading">
      {{ text.ctr }}
      <span class="timeline-ctr__total">
        <template v-if="selectedValueIsTotal">{{ timelineCtrTotal }}%</template>
        <template v-if="selectedValueIsMobile">{{ timelineCtrMobile }}%</template>
        <template v-if="selectedValueIsDesktop">{{ timelineCtrDesktop }}%</template>
      </span>
      <IconInfo class="timeline-ctr__info-icon" />
    </h6>
    <div class="timeline-ctr__dropdown">
      <div class="timeline-ctr__left-panel">
        <span class="timeline-ctr__text">
          <template v-if="selectedValueIsMobile">{{ text.total }}</template>
          <template v-if="selectedValueIsDesktop">{{ text.mobile }}</template>
          <template v-if="selectedValueIsTotal">{{ text.mobile }}</template>
        </span>
        <span class="timeline-ctr__text">
          <template v-if="selectedValueIsMobile">{{ text.desktop }}</template>
          <template v-if="selectedValueIsDesktop">{{ text.total }}</template>
          <template v-if="selectedValueIsTotal">{{ text.desktop }}</template>
        </span>
      </div>
      <div class="timeline-ctr__right-panel">
        <span class="timeline-ctr__text">{{ text.ctr }}</span>
        <span class="timeline-ctr__text timeline-ctr__text--soft">
          <template v-if="selectedValueIsMobile">{{ timelineCtrTotal }}%</template>
          <template v-if="selectedValueIsDesktop">{{ timelineCtrMobile }}%</template>
          <template v-if="selectedValueIsTotal">{{ timelineCtrMobile }}%</template>
        </span>
        <span class="timeline-ctr__text timeline-ctr__text--soft">
          <template v-if="selectedValueIsMobile">{{ timelineCtrDesktop }}%</template>
          <template v-if="selectedValueIsDesktop">{{ timelineCtrTotal }}%</template>
          <template v-if="selectedValueIsTotal">{{ timelineCtrDesktop }}%</template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IconInfo from '@/assets/img/svg/info.svg?inline'

export default {
  name: 'TimelineCtr',
  props: {
    timelineBoxAndPosition: {
      type: Object
    }
  },
  components: {
    IconInfo
  },
  data () {
    return {
      ctrHistoricalData: {},
      ctr: {
        total: 0,
        mobile: 0,
        desktop: 0
      },
      text: {
        ctr: 'CTR',
        mobile: 'Mobile',
        desktop: 'Desktop',
        total: 'Total'
      }
    }
  },
  computed: {
    ctrHistoricalDataLoading () {
      return this.$store.state.contentBlockItem.historicalCtrDataLoading
    },
    /**
     * Selected CTR value
     * @values mobile, desktop, total
     */
    selectedValue () {
      return this.$store.state.contentBlockItem.mainCtrSelect.value
    },
    /**
     * Selected value is mobile
     * @returns {boolean}
     */
    selectedValueIsMobile () {
      return this.selectedValue === 'mobile'
    },
    /**
     * Selected value is desktop
     * @returns {boolean}
     */
    selectedValueIsDesktop () {
      return this.selectedValue === 'desktop'
    },
    /**
     * Selected value is total
     * @returns {boolean}
     */
    selectedValueIsTotal () {
      return this.selectedValue === 'total'
    },
    /**
     * CTR total
     * @returns {string}
     */
    timelineCtrTotal () {
      if (this.ctrHistoricalData && this.ctrHistoricalData?.ctr) {
        return (this.ctrHistoricalData.ctr * 100).toFixed(2)
      }
      return '0'
    },
    /**
     * CTR mobile
     * @returns {string}
     */
    timelineCtrMobile () {
      if (this.ctrHistoricalData && this.ctrHistoricalData?.ctrPerDevice?.mobile) {
        return (this.ctrHistoricalData.ctrPerDevice.mobile * 100).toFixed(2)
      }
      return '0'
    },
    /**
     * CTR desktop
     * @returns {string}
     */
    timelineCtrDesktop () {
      if (this.ctrHistoricalData && this.ctrHistoricalData?.ctrPerDevice?.desktop) {
        return (this.ctrHistoricalData.ctrPerDevice.desktop * 100).toFixed(2)
      }
      return '0'
    }
  },
  methods: {
    loadHistoricalCtrData () {
      const box = this.$store.state.contentBlockItem.historicalCtrData.filter(
        item => item.box === this.timelineBoxAndPosition.timelineBox
      )[0]
      if (box && 'results' in box) {
        this.ctrHistoricalData = box.results.find(result => result.position === this.timelineBoxAndPosition.position)
      }
    }
  },
  mounted () {
    if (!this.ctrHistoricalDataLoading) {
      this.loadHistoricalCtrData()
    }
  },
  watch: {
    '$store.state.contentBlockItem.historicalCtrData' () {
      this.loadHistoricalCtrData()
    }
  }
}
</script>

<style scoped lang="scss">
  .timeline-ctr {
    margin: .5rem auto 0 auto;
    cursor: default;
    position: relative;
    &__text {
      font-family: "Roboto", sans-serif;
      font-size: .75rem;
      font-weight: 500;
      color: #465674;
      margin: 0;
      &:hover {
        + .timeline-ctr__dropdown {
          display: grid;
        }
      }
      &--soft {
        font-weight: 400;
      }
      &--heading {
        display: flex;
        align-items: center;
        gap: .2rem;
      }
    }
    &__total {
      font-weight: 400;
      margin-left: .2rem;
    }
    &__info-icon {
      width: rem(12px);
      height: auto;
      fill: #6599FE;
    }
    &__dropdown {
      background: #fff;
      border-radius: .375rem;
      display: none;
      grid-template-columns: max-content max-content;
      align-items: end;
      grid-gap: .5rem;
      position: absolute;
      bottom: -0.5rem;
      left: calc(100% + 1rem);
      z-index: 9;
      padding: .625rem 1rem;
      box-shadow: 0 0 8px rgba(124, 124, 124, .4);
    }
    &__left-panel {
      display: grid;
      text-align: right;
      padding: .438rem 0;
      grid-gap: .3rem;
    }
    &__right-panel {
      display: grid;
      text-align: center;
      background: #F5F6FA;
      padding: .438rem .5rem;
      grid-gap: .3rem;
    }
  }
</style>
