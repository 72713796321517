import Store from '../../store'

const getSiteTitle = (siteId) => {
  const site = Store.getters['site/siteById'](siteId)
  if (site && site.title) {
    return Store.getters['site/siteById'](siteId).title
  }
  return siteId
}

const getSiteShortTitle = (siteId) => {
  const site = Store.getters['site/siteById'](siteId)
  if (site && site.shortTitle) {
    return Store.getters['site/siteById'](siteId).shortTitle
  }
  return siteId
}

export default {
  getSiteTitle,
  getSiteShortTitle
}
