<template>
  <div
    class="btn-left"
    @click="clickHandler"
    :data-test="dataTest"
    :title="title"
  >
    <IconRight class="btn-left__icon" />
  </div>
</template>

<script>
import IconRight from '@/assets/img/svg/arrow-right.svg?inline'
export default {
  name: 'BtnLeft',
  components: {
    IconRight
  },
  props: {
    dataTest: {
      type: String,
      default: ''
    },
    clickHandler: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.btn-left {
  background: #6599FE;
  width: rem(30px);
  height: rem(30px);
  border-radius: rem(6px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  &:hover {
    background: darken(#6599FE, 5%);
  }
  &__icon {
    width: rem(7px);
    height: auto;
    fill: #fff;
    transform: rotate(180deg);
  }
}
</style>
