<template>
  <button
    class="btn-info"
    :data-test="dataTest"
    :title="title"
    @click="$emit('click', $event)"
  >
    <IconInfo class="btn-info__icon" />
  </button>
</template>

<script>
import IconInfo from '@/assets/img/svg/info.svg?inline'
export default {
  name: 'BtnInfo',
  props: {
    dataTest: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    IconInfo
  }
}
</script>

<style scoped lang="scss">
  .btn-info {
    background: #fff;
    width: rem(30px);
    height: rem(30px);
    border-radius: rem(4px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    transition: none;
    &:hover {
      border: 1px solid #6599FE;
      .btn-info__icon {
        fill: #6599FE;
      }
    }
    &__icon {
      width: rem(16px);
      height: auto;
      fill: #9DAAC4;
    }
  }
</style>
