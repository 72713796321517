<template>
  <div class="aspect-ratio">
    <iframe
      v-if="video.id"
      width="100%"
      height="100%"
      :src=url
      frameborder="0"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
import Config from '../../config'

export default {
  name: 'EagleVideo',
  props: {
    video: {
      type: Object
    }
  },
  computed: {
    url () {
      return Config.eagleVideoPlayer.baseUrl() + this.video.id
    }
  }
}
</script>

<style lang="scss">
div.aspect-ratio {
  padding-bottom: 56.25%;
  position: relative;
}

div.aspect-ratio iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
