<template>
  <div class="article-status">
    <span
      v-if="article.setting.status === 'draft'"
      class="article-status__badge article-status__badge--draft"
      data-test="article_status"
    >
      {{ $t('article.status_draft') }}
    </span>
    <span
      v-else-if="article.setting.status === 'review'"
      class="article-status__badge article-status__badge--review"
      data-test="article_status"
    >
      {{ $t('article.status_review') }}
    </span>
    <span
      v-else-if="article.setting.status === 'ready'"
      class="article-status__badge article-status__badge--ready"
      data-test="article_status"
    >
      {{ $t('article.status_ready') }}
    </span>
    <span
      v-else-if="article.setting.status === 'published' && isExpired"
      class="article-status__badge article-status__badge--published"
      data-test="article_status"
    >
      {{ $t('article.status_expired') }}
    </span>
    <span
      v-else-if="article.setting.status === 'published'"
      class="article-status__badge article-status__badge--published"
      data-test="article_status"
    >
      {{ $t('article.status_published') }}
    </span>
    <span
      v-else
      class="article-status__badge article-status__badge--review"
      data-test="article_status"
    >
      {{ article.setting.status }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ArticleStatus',
  props: {
    article: {
      type: Object
    }
  },
  computed: {
    isExpired () {
      return new Date(this.article.publishedUntil) < new Date()
    }
  }
}
</script>

<style scoped lang="scss">
  .article-status {
    display: inline-block;
    &__badge {
      font-family: "Roboto", sans-serif;
      font-size: rem(12px);
      display: inline-block;
      text-transform: uppercase;
      font-weight: 500;
      padding: rem(8px) rem(11px);
      border-radius: rem(4px);
      &--draft {
        color: #F57BA3;
        background: #FFEBEE;
      }
      &--review,
      &--ready {
        color: #FFA200;
        background: #FFF3E0;
      }
      &--published {
        color: #5AB963;
        background: #ECF7ED;
      }
    }
  }
</style>
