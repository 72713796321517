<template>
  <button
    class="btn-refresh"
    role="button"
    @click="clickHandler"
    :data-test="dataTest"
    :title="title"
  >
    <IconRefresh class="btn-refresh__icon" />
  </button>
</template>

<script>
import IconRefresh from '@/assets/img/svg/refresh.svg?inline'
export default {
  name: 'BtnRefresh',
  components: {
    IconRefresh
  },
  props: {
    dataTest: {
      type: String,
      default: ''
    },
    clickHandler: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-refresh {
    background: #fff;
    width: rem(30px);
    height: rem(30px);
    border-radius: rem(6px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    &:hover {
      border: 1px solid #6599FE;
      .btn-refresh__icon {
        fill: #6599FE;
      }
    }
    &__icon {
      width: rem(18px);
      height: rem(18px);
      fill: #9DAAC4;
    }
  }
</style>
