<template>
  <label
    class="btn btn-secondary m-b-5"
    :class="{ 'active': value }"
  >
    <input
      type="checkbox"
      :id="id"
      @change="$emit('input', $event.target.checked)"
      :checked="value"
      :disabled="disabled"
      :data-test="dataAttr ? dataAttr : id"
    >
    {{ label }}
  </label>
</template>

<script>
export default {
  name: 'InputCheckBox',
  props: {
    id: {
      type: String
    },
    label: {
      type: String
    },
    status: {
      type: String
    },
    value: {
      type: [Boolean, Number]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
