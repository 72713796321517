<template>
  <div>
    <div class=" row m-b-5">
      <div class="col-md-6">
        <h4>{{ $t('video.select') }}</h4>
      </div>
      <div class="col-md-6 text-right">
        <button
          type="button"
          class="btn btn-default"
          @click="close"
        >
          {{ $t('buttons.back') }}
        </button>
      </div>
    </div>
    <app-video-list
      show-select-button
      disable-actions
      disable-breadcrumb
      @select-record="selectVideo"
    >
    </app-video-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VideoList from '../video/VideoSimpleTableList'

export default {
  name: 'VideoList',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedVideo: null
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  components: {
    appVideoList: VideoList
  },
  methods: {
    selectVideo (video) {
      if (this.multiple === false) {
        this.selectedVideo = null
      }
      this.selectedVideo = video[0]
      this.addVideo()
    },
    addVideo () {
      this.$emit('set-data', this.selectedVideo)
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    }
  },
  beforeMount () {
    this.$store.commit('video/setPage', 1)
    this.$store.commit('video/resetFilter')
    this.$store.dispatch('video/fetch')
  }
}
</script>
