<template>
  <tr v-if="config.overviewInfo" class="tableSupHeader-overviewInfo">
    <th v-for="(fieldLabel, field, index) in config.fields" :key="`header-${index}`">
      <template v-if="index === 0">
        <slot name="first-column-slot"></slot>
      </template>
      <template v-else>
        <div v-if="config.overviewInfo[field]" class="overviewInfo-value">
          <span>
            {{ config.overviewInfo[field] }}
          </span>
        </div>
      </template>
    </th>
  </tr>
</template>

<script>
export default {
  name: 'TableSupHeader',
  props: {
    config: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .tableSupHeader-overviewInfo {
    height: 4.375rem;
    .overviewInfo-value {
      display: flex;
      align-items: center;
      height: 2.5rem;
      border-radius: 0.375rem;
      border: 1px solid #D1DBE4;
      background: #F2F4F8;
      overflow: hidden;
      color: #8490A7;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.875rem;
      font-weight: 700;
      padding: 0.25rem 0.5rem;
      margin-top: 0.4rem;
    }
  }
</style>
