<template>
  <div class="aspect-ratio">
    <iframe
      width="100%"
      height="100%"
      :src=url
      frameborder="0"
      allowfullscreen
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'OoyalaVideo',
  props: {
    id: {
      type: String
    }
  },
  computed: {
    url () {
      return '//player.ooyala.com/static/v4/production/skin-plugin/iframe.html?ec=' + this.id +
        '&pbid=50da6f4a01a7489cb0d470188310be41&pcode=AyMWkyOjhKHaxdqIe4W7Lnk62GeA' +
        '&initialBitrate=%7B%22level%22%3A1.0%2C%22duration%22%3A90%7D&iosPlayMode=inline'
    }
  }
}
</script>

<style lang="scss">
div.aspect-ratio {
  padding-bottom: 56.25%;
  position: relative;
}

div.aspect-ratio iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
