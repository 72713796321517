<template>
  <div>
    <button
      class="btn-article-create"
      type="button"
      data-test="article_create_btn"
      @click="showCreateModal"
    >
      <span class="btn-article-create__text">
        {{ $t("create_new_article") }}
      </span>
    </button>
    <ArticleCreateModal
      v-if="createModal"
      @close="closeCreateModal"
    />
  </div>
</template>

<script>
import ArticleCreateModal from '../article/ArticleCreateModal'

export default {
  name: 'BtnArticleCreate',
  data () {
    return {
      createModal: false
    }
  },
  components: {
    ArticleCreateModal
  },
  methods: {
    showCreateModal () {
      this.createModal = true
    },
    closeCreateModal () {
      this.createModal = false
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-article-create {
    padding-left: rem(15px);
    padding-right: rem(15px);
    font-family: "Roboto", sans-serif;
    font-size: rem(13px);
    font-weight: 500;
    color: #fff;
    background: #6599FE;
    border: none;
    border-radius: rem(6px);
    cursor: pointer;
    height: rem(40px);
    transition: background 100ms;
    display: flex;
    align-items: center;
    &:hover {
      background: darken(#6599FE, 10%);
    }
  }
</style>
