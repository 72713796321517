import axios from 'axios'
import appConfig from '../config'

const antaresApi = () => axios.create({
  baseURL: appConfig.antares.apiUrl(),
  timeout: appConfig.antares.apiTimeout * 1000,
  headers: {
    access_token: appConfig.antares.apiToken(),
    'Content-Type': 'application/json'
  }
})

export default antaresApi
