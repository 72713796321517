import { dateTimeToday } from '../services/DateTimeService'

export const SLOTS = [0, ...[...Array(17)].map((v, i) => i + 6)]
export const PLUS1_SLOT_SCHEDULE = {
  0: {
    4: 1,
    7: 1,
    23: 1,
    24: 1
  },
  6: {
    1: 1,
    2: 1,
    3: 1,
    4: 1,
    5: 1,
    7: 1,
    15: 1,
    23: 1,
    24: 1
  },
  8: {
    2: 1,
    5: 1,
    19: 1
  },
  9: {
    4: 1,
    15: 1
  },
  10: {
    1: 1,
    25: 1,
    34: 1
  },
  11: {
    2: 1,
    4: 2,
    23: 1,
    24: 1,
    34: 1
  },
  12: {
    3: 1,
    5: 1,
    7: 1,
    15: 1,
    19: 1
  },
  13: {
    4: 1
  },
  14: {
    1: 1,
    4: 1,
    5: 1,
    25: 2
  },
  15: {
    4: 1
  },
  16: {
    1: 1,
    2: 1,
    5: 1
  },
  17: {
    4: 1,
    34: 1
  },
  18: {
    3: 1,
    5: 1,
    7: 1,
    15: 1,
    19: 1,
    23: 1,
    24: 1,
    25: 1
  },
  19: {
    4: 2,
    5: 1
  },
  20: {
    5: 1
  },
  22: {
    2: 1
  }
}

export const PLUS1_SLOT_HOLIDAYS_SCHEDULE = {
  0: {
    4: 1
  },
  6: {
    15: 1
  },
  7: {
    1: 1,
    2: 1,
    3: 1,
    4: 1,
    5: 1
  },
  9: {
    2: 1,
    15: 1
  },
  10: {
    4: 1
  },
  11: {
    1: 1,
    5: 1
  },
  12: {
    2: 1,
    15: 1
  },
  13: {
    4: 1
  },
  14: {
    5: 1
  },
  16: {
    2: 1,
    4: 1
  },
  17: {
    1: 1
  },
  18: {
    3: 1,
    4: 1,
    15: 1
  },
  19: {
    5: 1
  },
  20: {
    4: 1
  }
}

export default {
  site: 1,
  date: dateTimeToday(),
  slot: 0,
  approved: false
}
