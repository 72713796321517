<template>
  <ModuleView
    :disableBreadcrumb="true"
    class="hp-topic-offer-list"
  >
    <template v-slot:body>
      <TopicOfferFilter @fetch="fetchList" />
      <Autoloader
        :callback="fetchList"
        :interval="10"
        ref="autoloader"
        :enabled="countdownEnabled"
      />
      <div class="hp-topic-offer-list__card">
        <div class="hp-topic-offer-list__time-slots">
          <HpTopicOfferTimeSlot
            :class="{'hp-topic-offer-time-slot--active': selectedSlot === 24}"
            @click="selectSlot(24)"
          >
            {{ $t('hpTopicOffer.all_slots') }}
          </HpTopicOfferTimeSlot>
          <HpTopicOfferTimeSlot
            v-for="(slot, index) in slots" :key="`slot-${slot}`"
            :class="{
            'hp-topic-offer-time-slot--active': selectedSlot === slot,
            [slotStateClass(slotList[index], slot)]: true,
          }"
            @click="selectSlot(slot)"
          >
            {{ ('0' + slot).substr(-2) }}:00
          </HpTopicOfferTimeSlot>
        </div>
        <table class="hp-topic-offer-list__table table">
          <thead class="hp-topic-offer-list__thead">
            <tr>
            <th v-if="selectable"></th>
            <th class="hp-topic-offer-list__th">
              <span>{{ $t('article.title') }}</span><br>
              <span class="hp-topic-offer-list__subtitle">{{ $t('hpTopicOffer.slot_base') }} -</span>
              <span class="hp-topic-offer-list__subtitle">{{ $t('hpTopicOffer.site') }} -</span>
              <span class="hp-topic-offer-list__subtitle">{{ $t('article.filter.rubric') }}</span>
            </th>
            <th class="hp-topic-offer-list__th">
              <span>{{ $t('hpTopicOffer.pageViews') }}</span>
            </th>
            <th class="hp-topic-offer-list__th">
              <span>{{ $t('article.order_date') }}</span><br>
              <span>{{ $t('hpTopicOffer.date') }}</span><br>
            </th>
            <th class="hp-topic-offer-list__th">{{ $t('hpTopicOffer.fb_shared') }}</th>
            <th class="hp-topic-offer-list__th">{{ $t('hpTopicOffer.list.headers.actions') }}</th>
          </tr>
          </thead>
          <tbody v-if="list.length">
          <template v-for="(slotItem, index) in slotList">
            <tr :key="`slotSection_${index}`" v-show="selectedSlot === 24">
              <th v-if="selectable"></th>
              <th class="hp-topic-offer-list__slot-title" colspan="4">SLOT {{ ('0' + slots[index]).substr(-2) }}:00</th>
            </tr>
            <tr
              v-for="item in slotItem.items" :key="item.id"
              v-show="[24, slots[index]].indexOf(selectedSlot) > -1"
              :class="{used: item.used}"
            >
              <td v-if="selectable" class="hp-topic-offer-list__td">
                <button
                  class="btn btn-default btn-sm"
                  :title="$t('buttons.select')"
                  @click="selectRecord(item.article)"
                >
                  {{ $t('buttons.select') }}
                </button>
              </td>
              <td class="hp-topic-offer-list__td">
                <div class="hp-topic-offer-list__mob-header">
                  {{ $t('article.title') }} /
                  {{ $t('hpTopicOffer.slot_base') }} -
                  {{ $t('hpTopicOffer.site') }} -
                  {{ $t('article.filter.rubric') }}
                </div>
                <span class="hp-topic-offer-list__td-title">{{ item.article.field.title }}</span>
                <br>
                <span class="hp-topic-offer-list__subtitle">
                    {{ ('0' + item.slot).substr(-2) }}:00 - {{ getUserDepartmentName(item.article.createdBy) }}
                  </span>
                <span class="hp-topic-offer-list__subtitle">{{ siteNameById(item.article.site) }}</span>
                <span class="hp-topic-offer-list__subtitle">{{ rubricNameById(item.article.mainRubric) }}</span>
              </td>
              <td class="hp-topic-offer-list__td">
                <div class="hp-topic-offer-list__mob-header">{{ $t('hpTopicOffer.pageViews') }}:</div>
                <span>{{ item.article.visits }}</span>
              </td>
              <td class="hp-topic-offer-list__td">
                <div class="hp-topic-offer-list__mob-header">
                  {{ $t('article.order_date') }} / {{ $t('hpTopicOffer.date') }}
                </div>
                <span>{{ item.article.orderDate | prettyDateTime }}</span><br>
                <span>{{ item.date | prettyDate }}</span>
              </td>
              <td class="hp-topic-offer-list__td hp-topic-offer-list__td--checkbox">
                <div class="hp-topic-offer-list__mob-header">{{ $t('hpTopicOffer.fb_shared') }}</div>
                <Checkbox
                  v-if="showFbSharedCheckbox"
                  :id="`hp_topic_offer_fb_share_${item.id}`"
                  v-model="item.article.setting.fbShared"
                  @input="toggleFbShared(item)"
                  class="hp-topic-offer-list__checkbox"
                />
              </td>
              <td class="hp-topic-offer-list__td">
                <div class="hp-topic-offer-list__mob-header">{{ $t('hpTopicOffer.list.headers.actions') }}</div>
                <div class="hp-topic-offer-list__action-area">
                  <ButtonDelete
                    v-if="showDeleteButton"
                    store-name="hpTopicOffer"
                    :record-id="item.id"
                    data-test="hp_topic_offer_btn_delete"
                  />
                  <BtnInfo
                    :title="$tc('buttons.info')"
                    @click="$router.push({ name: 'article_detail', params: { id: item.article.id }})"
                  />
                  <Checkbox
                    v-if="showApproveCheckbox"
                    :id="`hp_topic_offer_approved_${item.id}`"
                    v-model="item.approved"
                    @input="toggleApproved(item)"
                    :label="$tc('hpTopicOffer.approved')"
                    class="hp-topic-offer-list__checkbox-with-desc"
                  />
                </div>
              </td>
            </tr>
            <template v-if="selectable === false">
              <tr
                v-show="[24, slots[index]].indexOf(selectedSlot) > -1"
                v-for="(warning, idx) in slotItem.warnings"
                :key="`slot_warn_${index}_${idx}`"
              >
                <td colspan="5">{{ warning }}</td>
              </tr>
            </template>
          </template>
          </tbody>
        </table>
      </div>
    </template>
  </ModuleView>
</template>

<script>
import antaresApi from '@/api/antares'
import CbsApi from '@/api/contentBlockService'
import Autoloader from '@/components/Autoloader'
import BtnInfo from '@/components/buttons/BtnInfo'
import ButtonDelete from '@/components/buttons/ButtonDelete'
import Checkbox from '@/components/form/Checkbox'
import { dateTimeDayEnd, dateTimeDayStart, dateTimeNow } from '@/services/DateTimeService'
import HpTopicOfferTimeSlot from '@/components/hp/HpTopicOfferTimeSlot'
import ModuleView from '@/components/ModuleView'
import moment from 'moment'
import PermissionService from '@/services/PermissionService'
import TopicOfferFilter from '@/components/hp/HpTopicOfferFilter'
import { PLUS1_SLOT_HOLIDAYS_SCHEDULE, PLUS1_SLOT_SCHEDULE, SLOTS } from '@/model/HpTopicOffer'
import Vue from 'vue'

export default {
  name: 'HpTopicOfferList',
  props: {
    selectable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      countdownEnabled: false,
      selectedSlot: 24,
      slotWarnings: {},
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.HOMEPAGE_TOPIC_OFFER_PERMISSIONS
    }
  },
  components: {
    Autoloader,
    BtnInfo,
    ButtonDelete,
    Checkbox,
    HpTopicOfferTimeSlot,
    ModuleView,
    TopicOfferFilter
  },
  computed: {
    filter () {
      return this.$store.getters['hpTopicOffer/filter']
    },
    sites () {
      return this.$store.getters['site/all']
    },
    holidays () {
      return this.$store.getters['hpTopicOffer/holidays']
    },
    showDeleteButton () {
      return this.hasPermission(this.requiredPermissions.deleteButton) && !this.selectable
    },
    showApproveCheckbox () {
      return this.hasPermission(this.requiredPermissions.approveCheckbox) && !this.selectable
    },
    showFbSharedCheckbox () {
      return this.hasPermission(this.requiredPermissions.fbSharedCheckbox)
    },
    freeDay () {
      const date = moment(this.filter.date).toDate()
      const holidays = this.holidays[date.getFullYear()]
      return [0, 6].includes(date.getDay()) || (holidays && holidays.includes(date.toJSON()))
    },
    slots () {
      if (this.filter.site === 1) {
        return SLOTS.filter((slot) => {
          if (this.list.find((i) => i.slot === slot)) {
            return true
          }
          if (this.freeDay) {
            return PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot] && (
              this.filter.department === 0 || PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot][this.filter.department]
            )
          }
          return PLUS1_SLOT_SCHEDULE[slot] && (
            this.filter.department === 0 || PLUS1_SLOT_SCHEDULE[slot][this.filter.department]
          )
        })
      }
      return SLOTS
    },
    list () {
      return this.$store.getters['hpTopicOffer/list']
    },
    slotList () {
      const slotList = this.slots.map((slot) => {
        const items = this.$store.getters['hpTopicOffer/list'].filter(
          (hpTopicOffer) => (
            slot === hpTopicOffer.slot &&
            (this.selectable === false || hpTopicOffer.approved) &&
            [0, this.getUserDepartmentId(hpTopicOffer.article.createdBy)].indexOf(this.filter.department) > -1
          )
        )
        const warnings = []
        if (this.filter.site === 1) {
          let departments = this.freeDay ? PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot] : PLUS1_SLOT_SCHEDULE[slot]
          if (departments !== undefined) {
            departments = Object.entries(departments)
            if (this.freeDay && PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot][this.filter.department]) {
              departments = [[this.filter.department, PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot][this.filter.department]]]
            } else if (this.freeDay === false && PLUS1_SLOT_SCHEDULE[slot][this.filter.department]) {
              departments = [[this.filter.department, PLUS1_SLOT_SCHEDULE[slot][this.filter.department]]]
            }
            for (const [departmentId, approvedCount] of departments) {
              const approved = items.filter(
                (hpTopicOffer) =>
                  this.getUserDepartmentId(hpTopicOffer.article.createdBy) === +departmentId &&
                  hpTopicOffer.approved
              )
              if (approvedCount > approved.length) {
                warnings.push(
                  this.$t(
                    'hpTopicOffer.department_articles_missing',
                    { department: this.getDepartmentName(+departmentId) }
                  )
                )
              }
            }
          }
        } else {
          warnings.push(this.$t('hpTopicOffer.articles_missing'))
        }
        return { items, warnings }
      })
      return slotList
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    fetchList () {
      this.countdownEnabled = false
      this.$refs.autoloader.refreshCountdown()
      this.$store.dispatch('hpTopicOffer/fetch')
        .then(() => {
          this.list.forEach((hpTopicOffer) => {
            this.articleVisits(hpTopicOffer.article)
          })
          const documentIds = this.list.map((hpTopicOffer) => hpTopicOffer.article.documentId)
          this.getUsedArticles(documentIds)
          this.countdownEnabled = true
        })
    },
    getSiteTitle (id) {
      const site = this.sites.find((site) => site.id === id)
      if (site === undefined) return id
      return site.title
    },
    getCategoryTitleById (id) {
      const category = this.$store.getters['category/categoryById'](id)
      if (category) return category.title
      return id
    },
    getDepartmentName (id) {
      const department = this.$store.getters['department/getById'](id)
      if (department) {
        return department.name
      }
      return `department(${id})`
    },
    getUserDepartmentId (id) {
      return this.$store.getters['user/userById'](id)?.department
    },
    getUserDepartmentName (id) {
      const departmentId = this.$store.getters['user/userById'](id)?.department
      if (departmentId) {
        const department = this.$store.getters['department/getById'](departmentId)
        if (department) {
          return department.name
        }
        return `department(${departmentId})`
      }
      return `user(${id})`
    },
    toggleApproved (record) {
      this.$store.dispatch('hpTopicOffer/updateApproved', record)
    },
    async toggleFbShared (record) {
      try {
        await this.$store.dispatch('article/updateFbShared', record)
        this.list.filter(({ article }) => article.documentId === record.article.documentId).forEach(({ article }) => {
          article.setting.fbShared = record.article.setting.fbShared
        })
      } catch (error) {
        console.error(error)
      }
    },
    rubricNameById (id) {
      const rubric = this.$store.getters['rubric/rubricById'](id)
      if (rubric) {
        return rubric.title
      }
      return id
    },
    siteNameById (id) {
      const site = this.$store.getters['site/siteById'](id)
      if (site) {
        return site.name
      }
      return id
    },
    articleVisits (article) {
      if (process.env.NODE_ENV !== 'development') {
        antaresApi().get(`/getVisits?system=eagle&documentId=${article.documentId}&portal=` + this.siteNameById(article.site))
          .then((result) => {
            if (result.data && result.data.length) Vue.set(article, 'visits', result.data[0].count)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
    getUsedArticles (documentIds) {
      CbsApi().get(
        '/contentBlocks?limit=1000&filter_in[articleDocumentId]=' + documentIds.join(',') +
        '&filter_gte[publishedSince]=' + dateTimeDayStart(this.filter.date) +
        '&filter_lte[publishedUntil]=' + dateTimeDayEnd(this.filter.date) +
        '&filter_lte[publishedSince]=' + dateTimeNow()
      )
        .then((result) => {
          result.data.data.forEach((contentItem) => {
            this.list
              .filter((hpTopicOffer) => hpTopicOffer.article.documentId === contentItem.articleDocumentId)
              .forEach((hpTopicOffer) => {
                Vue.set(hpTopicOffer, 'used', true)
              })
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    selectSlot (slot) {
      this.selectedSlot = slot
    },
    selectRecord (record) {
      this.$emit('select-record', record)
      this.$emit('close')
    },
    slotStateClass (slotItem, slot) {
      if (slotItem.items.length === 0) {
        return 'empty'
      }
      if (this.filter.site === 1) {
        let departments = this.freeDay ? PLUS1_SLOT_HOLIDAYS_SCHEDULE[slot] : PLUS1_SLOT_SCHEDULE[slot]
        if (departments === undefined) {
          return 'delivered'
        }
        departments = Object.entries(departments)
        let state = 'approved'
        if (slotItem.warnings.length === departments.length) {
          state = 'empty'
        } else if (slotItem.warnings.length > 0) {
          state = 'delivered'
        }
        return state
      }
      if (slotItem.items.filter((item) => item.approved).length === 2) {
        return 'approved'
      }
      if (slotItem.items.length > 0) {
        return 'delivered'
      }
    }
  },
  created () {
    this.$store.dispatch('hpTopicOffer/fetchHolidays')
  },
  mounted () {
    this.fetchList()
  }
}
</script>

<style scoped lang="scss">
  .hp-topic-offer-list {
    display: grid;
    grid-gap: 1.75rem;
    @media screen and (min-width: 0px) and (max-width: 767px) {
      margin-top: 1rem;
    }
    &__card {
      background: #fff;
      padding: 1.25rem;
    }
    &__time-slots {
      display: flex;
      gap: 0.375rem;
      flex-wrap: wrap;
    }
    &__table {
      margin-top: 1.5rem;
      width: 100%;
      border-collapse: collapse;
    }
    &__thead {
      background: #F5F5F9;
      border-radius: rem(6px);
      padding: rem(16px) 0 rem(16px) rem(16px);
      @media screen and (min-width: 0px) and (max-width: 1339px) {
        display: none;
      }
    }
    &__td {
      vertical-align: middle !important;
    }
    &__th {
      font-family: "Roboto", sans-serif;
      font-size: 0.875rem;
      font-weight: 600;
      color: #8A96AC;
    }
    &__td-title {
      font-family: "Roboto", sans-serif;
      font-size: .875rem;
      font-weight: 500;
      color: #465674;
    }
    &__subtitle {
      font-family: "Roboto", sans-serif;
      font-size: .8125rem;
      font-weight: 400;
      opacity: .9;
      color: #8490a7;
    }
    &__slot-title {
      color: #6599fe;
    }
    &__checkbox {
      display: flex;
    }
    &__action-area {
      display: flex;
      gap: 0.625rem;
      align-items: center;
    }
    &__checkbox-with-desc {
      position: relative;
      background: #4caf50;
      border-radius: 0.25rem;
      color: #fff;
      padding: 0.25rem 0.875rem 1px .875rem;
      & [type=checkbox].filled-in:checked + label:after {
        background: #fff;
      }
      & [type=checkbox].filled-in:checked+label:before {
        border-right: 2px solid #4caf50!important;
        border-bottom: 2px solid #4caf50!important;
      }
      & [type=checkbox].filled-in:not(:checked)+label:after {
        border: 2px solid #fff;
      }
    }
    &__mob-header {
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      font-weight: 600;
      color: #6599fe;
      margin-bottom: .5rem;
      @media screen and (min-width: 1340px){
        display: none;
      }
    }
  }
  .used {
    background: rgba(101, 153, 254, 0.1);
  }
  @media screen and (min-width: 0px) and (max-width: 1339px) {
    table, thead, tbody, th, td, tr {
      display: block;
    }
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    tr { border: 1px solid #ccc; }
    td {
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }
    td:before {
      display: block;
      width: 100%;
    }
  }
</style>
