<template>
  <div>
    <app-button-copy-to-clipboard
      v-if="hideCopyToClipboardButton === false"
      class="m-b-10"
      data-test="article_btn_url_copy_to_clipboard"
      :data="url"
      :notify-message="$t('notify.url_was_copied')"
    >
    </app-button-copy-to-clipboard>
    <app-article-button-refresh-url
      v-if="hideRefreshUrlButton === false"
      :article="article"
      :disabled="!showRefreshButton"
      data-test="article_btn_url_copy_to_clipboard"
      @update-url="setUrlFromMainRoute"
    >
    </app-article-button-refresh-url>

    <label v-if="hideLabels === false">
      {{ $t('article.url_link') }}:
    </label>
    <a :href="url" style="font-size:13px" target="_blank">{{ url }}</a>
    <br>
    <app-button-copy-to-clipboard
      v-if="hideCopyToClipboardButton === false"
      data-test="article_btn_gallery_copy_to_clipboard"
      :data="galleryUrl"
      :notify-message="$t('notify.url_was_copied')"
    >
    </app-button-copy-to-clipboard>
    <label v-if="hideLabels === false">
      {{ $t('article.url_gallery_link') }}:
    </label>
    <a :href="galleryUrl" style="font-size:13px" target="_blank">{{ galleryUrl }}</a>
  </div>
</template>
<script>
import CoreApi from '../../api/core'
import BtnCopyToClipboard from '../buttons/BtnCopyToClipboard'
import ArticleButtonRefreshUrl from './ArticleButtonRefreshUrl'
import { mapGetters } from 'vuex'

export default {
  name: 'ArticleUrlInfo',
  props: {
    article: {
      type: Object
    },
    hideRefreshUrlButton: {
      type: Boolean,
      default: false
    },
    hideCopyToClipboardButton: {
      type: Boolean,
      default: false
    },
    hideLabels: {
      type: Boolean,
      default: false
    }
  },
  components: {
    appButtonCopyToClipboard: BtnCopyToClipboard,
    appArticleButtonRefreshUrl: ArticleButtonRefreshUrl
  },
  data () {
    return {
      url: '',
      suggestedUrl: '',
      galleryUrl: ''
    }
  },
  computed: {
    showRefreshButton () {
      if (this.suggestedUrl !== this.url) {
        return true
      }
      return false
    },
    articleModifiedAt () {
      return this.article.modifiedAt
    },
    ...mapGetters(['vlm'])
  },
  watch: {
    articleModifiedAt () {
      this.getUrl()
        .then(() => {
          if (this.article.routes.length > 0) {
            this.setUrlFromMainRoute(this.article)
          }
        })
    }
  },
  methods: {
    async getUrl () {
      await CoreApi().get('/article/' + this.article.id + '/route/suggest')
        .then((response) => {
          this.url = response.data
          this.suggestedUrl = response.data
          this.galleryUrl = this.getGalleryUrl(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    getGalleryUrl (articleUrl) {
      const url = new URL(articleUrl)
      let port = ''
      if (url.port !== '') {
        port = ':' + url.port
      }
      if (this.vlm) {
        return url.protocol + '//' + url.hostname + port + '/galerie-' + url.pathname.replace(/\/$/, '').split('/').pop()
      }
      return url.protocol + '//' + url.hostname + port + '/gal' + url.pathname
    },
    setUrlFromMainRoute (article) {
      const mainRoute = this.getMainRoute(article)
      if (mainRoute) {
        this.url = mainRoute.absoluteUrl
        this.galleryUrl = this.getGalleryUrl(mainRoute.absoluteUrl)
      }
    },
    getMainRoute (article) {
      return article.routes.find(route => {
        if (route.isMain) {
          return route
        }
      })
    }
  },
  created () {
    this.getUrl()
      .then(() => {
        if (this.article.routes.length > 0) {
          this.setUrlFromMainRoute(this.article)
        }
      })
  }
}
</script>

<style lang="scss">

</style>
