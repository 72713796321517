<template>
  <button
    class="btn-delete"
    :data-test="dataTest"
    :title="title"
    @click="$emit('click', $event)"
  >
    <IconDelete class="btn-delete__icon" />
  </button>
</template>

<script>
import IconDelete from '@/assets/img/svg/delete.svg?inline'
export default {
  name: 'BtnDelete',
  props: {
    dataTest: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    IconDelete
  }
}
</script>

<style scoped lang="scss">
.btn-delete {
  background: #fff;
  width: rem(30px);
  height: rem(30px);
  border-radius: rem(4px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border: 1px solid #6599FE;
    .btn-delete__icon {
      fill: #6599FE;
    }
  }
  &__icon {
    width: rem(16px);
    height: auto;
    fill: #9DAAC4;
  }
}
</style>
