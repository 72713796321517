<template>
    <span>
        <span class="label label-primary" v-if="flag.video">{{ $t('article.video') }}</span>
        <span class="label label-primary" v-if="flag.imageGallery">{{ $t('article.gallery') }}</span>
        <span class="label label-primary" v-if="flag.exclusive">{{ $t('article.exclusive') }}</span>
        <span class="label label-primary" v-if="flag.commentary">{{ $t('article.commentary') }}</span>
        <span class="label label-primary" v-if="flag.interview">{{ $t('article.interview') }}</span>
        <span class="label label-primary" v-if="flag.online">{{ $t('article.online') }}</span>
        <span class="label label-primary" v-if="flag.poll">{{ $t('article.poll') }}</span>
        <span class="label label-primary" v-if="flag.updated">{{ $t('article.updated') }}</span>
        <span class="label label-primary" v-if="flag.justNow">{{ $t('article.just_now') }}</span>
        <span class="label label-primary" v-if="flag.native">{{ $t('article.native') }}</span>
        <span class="label label-primary" v-if="flag.photostory">{{ $t('article.photostory') }}</span>
        <span class="label label-primary" v-if="flag.quiz">{{ $t('article.quiz') }}quiz</span>
        <span class="label label-primary" v-if="flag.likeDislike">{{ $t('article.like_dislike') }}</span>
    </span>
</template>

<script>
export default {
  props: {
    flag: {
      type: Object
    }
  }
}
</script>
