<template>
  <button
    :class="['btn-edit', { 'btn-edit--is-disabled': btnIsDisabled }]"
    :data-test="dataTest"
    :title="title"
    @click="$emit('click', $event)"
  >
    <IconEdit class="btn-edit__icon" />
  </button>
</template>

<script>
import IconEdit from '@/assets/img/svg/edit.svg?inline'
export default {
  name: 'BtnEdit',
  props: {
    dataTest: {
      type: String,
      default: ''
    },
    btnIsDisabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    IconEdit
  }
}
</script>

<style scoped lang="scss">
  .btn-edit {
    background: #fff;
    width: rem(30px);
    height: rem(30px);
    border-radius: rem(4px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border: 1px solid #6599FE;
      .btn-edit__icon {
        fill: #6599FE;
      }
    }
    &--is-disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    &__icon {
      width: rem(16px);
      height: auto;
      fill: #9DAAC4;
    }
  }
</style>
