<template>
  <div>
    <div class="form-group">
      <CheckboxButton
        v-model="item.flag.video"
        id="item_flag_video"
        :label="$tc('article.video')"
      />
      <CheckboxButton
        v-model="item.flag.imageGallery"
        id="item_flag_imageGallery"
        :label="$tc('article.image_gallery')"
      />
      <CheckboxButton
        v-model="item.flag.exclusive"
        id="item_flag_exclusive"
        :label="$tc('article.exclusive')"
      />
      <CheckboxButton
        v-model="item.flag.commentary"
        id="item_flag_commentary"
        :label="$tc('article.commentary')"
      />
      <CheckboxButton
        v-model="item.flag.interview"
        id="item_flag_interview"
        :label="$tc('article.interview')"
      />
      <CheckboxButton
        v-model="item.flag.online"
        id="item_flag_online"
        :label="$tc('article.online')"
      />
      <CheckboxButton
        v-model="item.flag.updated"
        id="item_flag_updated"
        :label="$tc('article.updated')"
      />
      <CheckboxButton
        v-model="item.flag.justNow"
        id="item_flag_justNow"
        :label="$tc('article.just_now')"
      />
      <CheckboxButton
        v-model="item.flag.poll"
        id="item_flag_poll"
        :label="$tc('article.poll')"
      />
      <CheckboxButton
        v-model="item.flag.photostory"
        id="item_flag_photostory"
        :label="$tc('article.photostory')"
      />
      <CheckboxButton
        v-model="item.flag.quiz"
        id="item_flag_quiz"
        :label="$tc('article.quiz')"
      />
      <CheckboxButton
        v-model="item.flag.likeDislike"
        id="item_flag_likeDislike"
        :label="$tc('article.like_dislike')"
      />
    </div>
    <div class="form-group">
      <div class="row">
        <div class="col-6" v-if="item.flag.updated">
          <Datepicker
            v-model="item.flag.updatedUntilDatetime"
            id="item_updatedUntilDatetime"
            :label="$tc('article.updated_until_datetime')"
          />
        </div>
        <div v-if="item.flag.justNow" class="col-6">
          <Datepicker
            v-model="item.flag.justNowUntilDatetime"
            id="item_justNowUntilDatetime"
            :label="$tc('article.just_now_until_datetime')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxButton from '../form/inputs/InputCheckBox'
import Datepicker from '../form/Datepicker'

export default {
  name: 'FlagCheckboxes',
  props: {
    item: {
      type: Object
    }
  },
  components: {
    CheckboxButton,
    Datepicker
  }
}
</script>
