<template>
  <div class="timeline-row">
    <div class="timeline-row__left-box">
      <span class="timeline-row__position">{{ $t('hp.position') }} #{{ position }}</span>
      <button
        class="timeline-row__btn-add-article"
        @click="showCreateModal(position, timelineConfig.times[0])"
        data-test="hp_timeline_add"
      >
        {{ type === 'video' ? $t('hp.add_video') : $t('hp.add_article') }}
      </button>
      <TimelineCtr v-if="type !== 'video'" :timeline-box-and-position="timelineBoxAndPosition" />
    </div>
    <div
      v-for="(time, index) in timelineConfig.times"
      :data-published-since="time"
      class="timeline-row__box-single hp-box"
      :key="`item-${time + index}`"
      @drop.prevent="onDrop($event, time)"
      @dragover.prevent
      @dragenter.prevent
      @dragenter="onDragEnter($event, 1)"
      @dragleave="onDragLeave($event, 1)"
    >
      <TimelineAddArticle
        :copied-item="copiedItem"
        :type="type"
        :required-permissions="requiredPermissions"
        @btn-plus-click="showCreateModal(position, time)"
        @btn-paste-click="paste(position, time)"
        class="timeline-row__add-article"
      />
    </div>
    <slot name="timelineArticle"></slot>
  </div>
</template>

<script>
import TimelineAddArticle from '@/components/contentBlockItem/timeline/TimelineAddArticle'
import TimelineCtr from '@/components/contentBlockItem/timeline/TimelineCtr'

export default {
  name: 'TimelineRow',
  components: {
    TimelineAddArticle,
    TimelineCtr
  },
  props: {
    type: {
      type: String,
      default: 'article'
    },
    position: {
      type: Number,
      required: true
    },
    showCreateModal: {
      type: Function,
      required: true
    },
    timelineConfig: {
      type: Object,
      required: true
    },
    copiedItem: {
      type: Object,
      required: true
    },
    paste: {
      type: Function,
      required: true
    },
    timelineBoxAndPosition: {
      type: Object,
      required: true
    },
    requiredPermissions: {
      type: Object,
      required: true
    }
  },
  methods: {
    onDrop (e, time) {
      e.target.style.background = '#F2F6FC'
      this.$emit('paste-dragged-item', {
        position: this.position,
        time: time
      })
    },
    onDragEnter (e) {
      e.target.style.background = '#6599FE'
    },
    onDragLeave (e) {
      e.target.style.background = '#F2F6FC'
    }
  }
}
</script>

<style scoped lang="scss">
  .timeline-row {
    width: rem(3600px);
    display: flex;
    position: relative;
    // overflow: hidden;
    &:not(:last-child) {
      margin-bottom: rem(10px);
    }
    &__left-box {
      width: rem(140px);
      border: 1px solid #D1DBE4;
      padding: rem(15px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: rem(115px);
      z-index: 5;
    }
    &__ctr {
      bottom: -5px;
    }
    &__position {
      font-family: "Roboto", sans-serif;
      font-size: rem(12px);
      color: #465674;
      font-weight: 500;
      text-align: center;
      display: block;
      margin-bottom: rem(6px);
    }
    &__btn-add-article {
      font-family: "Roboto", sans-serif;
      font-size: rem(14px);
      color: #fff;
      font-weight: 500;
      background: #6599FE;
      border-radius: rem(6px);
      border: none;
      padding: rem(5px) rem(11px);
      cursor: pointer;
      &:hover {
        background: darken(#6599FE, 10%);
      }
    }
    &__box-single {
      background: #f5f6fa;
      border: 1px solid #D1DBE4;
      border-left: 0;
      padding: 1px 0;
      width: 140px;
      position: relative;
      cursor: pointer;
      &:hover {
        .timeline-row__add-article {
          display: block;
        }
      }
    }
  }
</style>
