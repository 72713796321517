<template>
  <div class="tooltip-info-text" :style="{ '--top': position.y + 'px', '--left': position.x + 'px', '--text-align': textAlign }">
    <span v-html="title"></span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TooltipInfoText',
  props: {
    position: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    textAlign: {
      type: String,
      default: 'center'
    }
  },
  data () {
    return {
      showToolTip: false
    }
  }
}
</script>

<style lang="scss" scoped>

.tooltip-info-text {
  max-width: 350px;
  position: absolute;
  bottom: var(--top);
  left: var(--left);
  transform: translate(-40%, 0%);
  padding: 10px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2);
  transition: all .15s ease-in-out;
  z-index: 99;
  text-align: var(--textAlign);

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
  }
}

</style>
