<template>
    <span>
        <button
          type="button"
          title="Regenerate url"
          @click="showRegenerateUrlModal"
          class="btn btn-default btn-sm m-b-10"
          :disabled="disabled"
        >
            <i class="fas fa-sync"></i> {{ label }}
        </button>

        <app-modal
          v-if="regenerateUrlModal"
          :title="$t('article.modal.refresh_url_header')"
          modal-size="modal-md"
          close-on-click-self
          @close="close"
        >
            <!-- Body -->
            <template slot="body">
                <div class="text-center">
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="generateUrl"
                      data-test="before_route_leave_btn_yes"
                    >
                        {{ $t('article.modal.yes') }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-inverse"
                      @click="close"
                      data-test="before_route_leave_btn_no"
                    >
                        {{ $t('article.modal.no') }}
                    </button>
                </div>
            </template>
        </app-modal>
    </span>
</template>

<script>
import Modal from '../shared/Modal'
import NotifyService from '../../services/NotifyService'
import CoreApi from '../../api/core'

export default {
  name: 'ArticleButtonRefreshUrl',
  props: {
    label: {
      type: String,
      default: ''
    },
    article: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      regenerateUrlModal: false
    }
  },
  components: {
    appModal: Modal
  },
  methods: {
    generateUrl () {
      CoreApi().patch('article/' + this.article.id + '/route/generate')
        .then((response) => {
          if (response.status !== 200) {
            NotifyService.setErrorMessage(response.statusText)
            return
          }
          this.$emit('update-url', response.data)
          NotifyService.setSuccessMessage(this.$t('article.notify.article_url_refresh_success'))
          this.close()
        })
        .catch((error) => {
          console.log(error.response)
          NotifyService.setErrorMessage(this.$t('article.notify.article_url_refresh_error'))
          this.close()
        })
    },
    getMainRoute (article) {
      article.routes.find(route => {
        if (route.isMain) {
          return route
        }
      })
    },
    showRegenerateUrlModal () {
      this.regenerateUrlModal = true
    },
    close () {
      this.regenerateUrlModal = false
    }
  }
}
</script>
