<template>
  <div>
    <p v-if="article.setting.status !== 'published'" class="alert alert-warning">
      <i class="fa fa-exclamation-triangle"></i>
      {{ $t('hp.article_status_warning') }}
    </p>
    <table class="table table-striped table-bordered">
      <tbody>
      <tr>
        <th class="text-right" width="250">{{ $t('hp.article.status') }}:</th>
        <td>
          <app-article-status :article="article"></app-article-status>
        </td>
      </tr>
      <tr>
        <th class="text-right" width="250">{{ $t('hp.article.title') }}:</th>
        <td>
          {{ article.field.title }}
        </td>
      </tr>
      <tr>
        <th class="text-right">{{ $t('hp.article.published_since') }} - {{ $t('hp.article.published_until') }}:</th>
        <td>
          {{ article.publishedSince | prettyDateTime }} -
          {{ article.publishedUntil | prettyDateTime }}
        </td>
      </tr>
      <tr>
        <th class="text-right">{{ $t('hp.article.order_date') }}:</th>
        <td>
          {{ article.orderDate | prettyDateTime }}
        </td>
      </tr>
      <tr>
        <th class="text-right">{{ $t('hp.article.author') }}:</th>
        <td>
                        <span v-for="(author, index) in article.expanded.authors" :key="`item-${index}`">
                            {{ author.name }}<br>
                        </span>
        </td>
      </tr>
      <tr>
        <th class="text-right">{{ $t('hp.article.rubric') }}:</th>
        <td>
          {{ article.expanded.site.title }} /
          {{ article.expanded.mainRubric.title }}
        </td>
      </tr>
      <tr>
        <th class="text-right">{{ $t('hp.article.image') }}:</th>
        <td>
          <div class="row">
            <div class="col-lg-6">
              <app-media
                v-if="article.expanded.listingImage"
                :media="article.expanded.listingImage"
                :width="480"
                :height="270"
              >
              </app-media>
              <app-media
                v-else-if="article.expanded.heroImage"
                :media="article.expanded.heroImage"
                :width="480"
                :height="270"
              >
              </app-media>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <th class="text-right">{{ $t('hp.article.first_paragraph') }}:</th>
        <td>
          {{ article.field.firstParagraph }}
        </td>
      </tr>
      <tr>
        <th class="text-right">{{ $t('hp.article.flags') }}</th>
        <td>
          <app-flags :flag="article.flag"></app-flags>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Flags from '../article/ArticleFlags'
import Media from '../shared/Media'
import ArticleStatus from '../article/ArticleStatus'

export default {
  name: 'InfoArticle',
  props: {
    article: {
      type: Object
    }
  },
  components: {
    appFlags: Flags,
    appMedia: Media,
    appArticleStatus: ArticleStatus
  }
}
</script>
